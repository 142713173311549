.container {
  width: 900px;
  margin: auto;
  left: 0px;
  right: 0px;
  padding-top: 120px;
  padding-bottom: 120px;

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 50px);
    padding: 25px;
  }
}

.page {
  @media only screen and (max-width: 768px) {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
}

.imageLine {
}

.image {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: -8px;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.1);
  }
}

.header {
}

.title {
  font-weight: 500;
  color: rgba($color: #000000, $alpha: 0.76);
  letter-spacing: -1px;
  font-size: 40px;
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 12px;

  @media only screen and (max-width: 768px) {
    & span {
      display: block;
      margin-top: 10px;
    }
  }
}

.dash {
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.description {
  font-weight: 300;
  font-size: 30px;
  color: rgba($color: #000000, $alpha: 0.6);
  max-width: 600px;
  line-height: 1.35;
  margin: 0px;
  padding: 0px;
}

.workTitle {
  margin-top: 100px;
  font-size: 24px;
  color: rgba($color: #000000, $alpha: 0.8);
}

.works {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 50px;
  column-gap: 40px;
  margin-bottom: 140px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 100%;
  }
}

.work {
  background-color: rgba($color: #c5cacd, $alpha: 0.1);
  border: 1px solid rgba($color: #c5cacd, $alpha: 0.2);
  padding: 30px;
  border-radius: 16px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-decoration: none;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.03);
  }
}

.iconLine {
  display: flex;
  margin-bottom: 50px;
  transition: all 0.5s;
}

.iconBox {
  width: fit-content;
  padding: 14px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  border-radius: 14px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.lightIcon {
  background-color: white;
  // border: 1px solid rgba($color: #000000, $alpha: 0.8);
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.workName {
  color: rgba($color: #000000, $alpha: 0.8);
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.workDescription {
  color: rgba($color: #000000, $alpha: 0.55);
  font-weight: 400;
  font-size: 22px;
  line-height: 1.4;
}

.links {
  display: flex;
  gap: 15px;
  padding-top: 50px;
  padding-bottom: 50px;

  & a {
    background-color: #fafafa;
    padding: 10px;
    border: 1px solid rgba($color: #000000, $alpha: 0.04);
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
    border-radius: 8px;
    transition: all 500ms;
    color: rgba($color: #000000, $alpha: 0.4);

    &:hover {
      transform: scale(1.2);
      color: rgba($color: #000000, $alpha: 0.8);
    }
  }
}
